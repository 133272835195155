import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Part, PartLog } from "../models/Part";

const moment = getMomentTz();

export const getParts = async () => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  const resp = await apiService.callV2("[M2].[GetParts]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Part[];
  } else {
    throw new Error(resp.message || "Error fetching parts");
  }
};

export const getPartLogs = async (partId: number) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [{ name: "PartId", value: partId }];

  const resp = await apiService.callV2("[M2].[GetPartLogs]", parameters);

  if (resp.ok) {
    let rows = get(resp, "data.tables[0].rows", []);

    rows = rows.map((pL: PartLog) => ({
      ...pL,
      Timestamp: pL.Timestamp ? moment.utc(pL["Timestamp"]).toDate() : null,
    }));

    return rows as PartLog[];
  } else {
    throw new Error(resp.message || "Error fetching part logs");
  }
};
