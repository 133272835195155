import { ErrorModal } from "@dexteel/mesf-core";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialogRaw } from "../../controls/modals/ConfirmationDialogRaw";
import {
  autocompleteOptionColor,
  useAutocompleteOptionStyles,
} from "../../utils/theme";
import { Guide } from "../models/Guide";
import { getGuides } from "../repositories/GuideRepository";

type Props = {
  label: string;
  typeCode: string;
  value: number | null;
  onChange?: Function;
  disabled?: boolean;
  onForceAssembly?: (force: boolean) => void;
};

const useGuides = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Guide[], Error>({
    queryKey: ["guides", partId],
    queryFn: () => getGuides(partId),
    onError: onError,
  });
};

export const GuideSelector = ({
  label,
  typeCode,
  value,
  onChange = () => {},
  disabled = false,
  onForceAssembly = () => {},
}: Props) => {
  const classes = useAutocompleteOptionStyles();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState<Guide | null>(null);

  const { data: guides, isLoading } = useGuides(undefined, (error) =>
    setError(error.message)
  );

  const selectedGuide = guides?.find((guide) => guide.PartId === value) || null;

  const inUse = (props: Guide | null) => {
    if (props?.InUse && !props.IsUnident) return true;
    return false;
  };

  const handleConfirmationClose = (result?: string) => {
    setMessage("");
    if (result === "OK") {
      onForceAssembly(true);
      onChange(selectedValue?.PartId ?? null);
      setSelectedValue(null);
      return;
    }
  };

  const unidentId = guides?.find(
    (g) => g.IsUnident && g.GuideTypeCode === typeCode
  )?.PartId;
  const checkboxChecked = unidentId === value;

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box style={{ flex: 3 }}>
          <Autocomplete
            id="clear-on-escape"
            clearOnEscape
            options={
              guides?.filter(
                (guide) =>
                  guide.GuideTypeCode === typeCode &&
                  (checkboxChecked || !guide.IsUnident)
              ) || []
            }
            getOptionLabel={(option) => option.PartName}
            renderOption={(props, option) => {
              return (
                <Box
                  bgcolor={inUse(props) ? autocompleteOptionColor : ""}
                  component="li"
                  className={classes.optionBox}
                >
                  <span>
                    <Typography>
                      {props.PartName}
                      <i>
                        {inUse(props)
                          ? props?.PartId == value
                            ? " (Actual) "
                            : " (In Use)"
                          : ""}
                      </i>
                    </Typography>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "8px",
                      marginTop: 7,
                    }}
                  >
                    <Chip
                      size="small"
                      variant="outlined"
                      color="primary"
                      label={`Location: ${props?.LocationName ?? "-"}`}
                    />
                  </div>
                </Box>
              );
            }}
            getOptionSelected={(option, value) =>
              option.PartId === value.PartId
            }
            disabled={disabled || checkboxChecked}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <>
                          {params.InputProps.endAdornment}
                          {value ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/configuration/guides/${value}`)
                              }
                              size="small"
                              title="See in guides"
                            >
                              <VisibilityIcon />
                            </IconButton>
                          ) : null}
                        </>
                      )}
                    </>
                  ),
                }}
              />
            )}
            value={selectedGuide}
            onChange={(event, newValue) => {
              if (inUse(newValue)) {
                setMessage(
                  `The guide ${newValue?.PartName} is already in use by another carrier, do you want to disassemble it?`
                );
                setSelectedValue(newValue);
              } else onChange(newValue?.PartId ?? null);
            }}
          />
        </Box>
        <Box style={{ flex: 2, display: "flex", justifyContent: "flex-start" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={() => {
                  if (!checkboxChecked) {
                    onChange(unidentId);
                  } else {
                    onChange(null);
                  }
                }}
                name="Unidentified"
                color="primary"
              />
            }
            label="Unidentified"
          />
        </Box>
      </Box>
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading guides"
      />
      <ConfirmationDialogRaw
        title="CONFIRMATION"
        message={message}
        open={message !== ""}
        onClose={handleConfirmationClose}
      />
    </>
  );
};
