import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../controls/buttons/GridButtons";
import { LinearProgressWithLabel } from "../../../../shared/components/LinearProgressWithLabel";
import { Campaign } from "../../models/Campaign";

type Props = {
  setSelectedRowData: (campaign: Campaign) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSelectedRowData,
  setShowModal,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    // Hide
    {
      field: "ScheduledTons",
      headerName: "Sched. Tons",
      hide: true,
    },
    {
      field: "ActualTons",
      headerName: "Actual Tons",
      hide: true,
    },
    // Visible
    {
      field: "CampaignNumber",
      headerName: "Number",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "SectionName",
      headerName: "Section",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Start",
      headerName: "Start",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        dxtToLocalServerTime((params.data as Campaign)?.Start, "MM/dd/yy") ||
        "",
    },
    {
      field: "End",
      headerName: "End",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        dxtToLocalServerTime((params.data as Campaign)?.End, "MM/dd/yy") || "",
    },
    {
      field: "Progress Bar",
      headerName: "Progress",
      minWidth: 20,
      flex: 2,
      cellRenderer: (params: ICellRendererParams) => {
        const campaign: Campaign = params.data;
        const actTons = campaign.ActualTons ?? 0;
        const totTons = campaign.ScheduledTons ?? 1;
        const value = (actTons / totTons) * 100;
        return (
          <div
            style={{
              padding: 10,
              alignContent: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <LinearProgressWithLabel value={value} />
          </div>
        );
      },
    },
    {
      field: "-",
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setSelectedRowData,
        id: params.data as Campaign,
        edit: false,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "campaignContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};
