import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Campaign } from "../models/Campaign";

const moment = getMomentTz();

export const getCampaigns = async (campaignId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (campaignId) parameters.push({ name: "CampaignId", value: campaignId });

  const resp = await apiService.callV2("[M2].[GetCampaigns]", parameters);

  if (resp.ok) {
    let rows = get(resp, "data.tables[0].rows", []);

    rows = rows.map((campaign: Campaign) => ({
      ...campaign,
      Start: campaign.Start ? moment.utc(campaign["Start"]).toDate() : null,
      End: campaign.End ? moment.utc(campaign["End"]).toDate() : null,
    }));

    return rows as Campaign[];
  } else {
    throw new Error(resp.message || "Error fetching campaign");
  }
};

export const upsertCampaign = async (campaign: Campaign) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "CampaignId", value: campaign.CampaignId },
    { name: "CampaignNumber", value: campaign.CampaignNumber },
    { name: "SectionId", value: campaign.SectionId },
    { name: "Start", value: campaign.Start },
    { name: "End", value: campaign.End },
    { name: "ScheduledBillets", value: campaign.ScheduledBillets },
    { name: "ActualBillets", value: campaign.ActualBillets },
    { name: "ScheduledTons", value: campaign.ScheduledTons },
    { name: "ActualTons", value: campaign.ActualTons },
    { name: "Comments", value: campaign.Comments },
    { name: "IsActive", value: true },
  ];

  const resp = await apiService.callV2("[M2].[UpsertCampaign]", parameters);

  if (resp.ok) {
    return get(resp, "data.returnValue", null) as number;
  } else {
    throw new Error(resp.message || "Error upserting campaign");
  }
};

export const deleteCampaign = async (campaignId: number) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [{ name: "CampaignId", value: campaignId }];

  const resp = await apiService.callV2("[M2].[DeleteCampaign]", parameters);

  if (!resp.ok) {
    throw new Error(resp.message || "Error deleting campaign");
  }
};
