import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../controls/LazyLoading";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";

import { useQuery } from "@tanstack/react-query";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { Roll } from "../../../../shared/models/Roll";
import { Rollset } from "../../../../shared/models/Rolllset";
import {
  FilterSearch,
  getReadyRolls,
  getReadyRollsets,
} from "../../../../shared/repositories/FilterRollsRepository";
import {
  LocationSelector,
  SectionSelector,
} from "../../../../shared/selectors";
import { useGridStyles } from "../../../configuration/styles/gridStyles";
import { useFilterRollData } from "./useFilterRollData";

type useRollsProps = {
  filterSearch?: FilterSearch | undefined;
  onError: (error: Error) => void;
  onSuccess?: (data: Rollset[]) => void;
};

const getRollsets = ({ filterSearch, onError, onSuccess }: useRollsProps) => {
  return useQuery<Rollset[], Error>({
    queryKey: ["rollsets", JSON.stringify(filterSearch)],
    queryFn: () => getReadyRollsets(filterSearch),
    onError: onError,
    onSuccess: onSuccess,
  });
};

const getRolls = ({ filterSearch, onError }: useRollsProps) => {
  return useQuery<Roll[], Error>({
    queryKey: ["rolls-filtered", JSON.stringify(filterSearch)],
    queryFn: () => getReadyRolls(filterSearch),
    onError: onError,
  });
};

type Props = {
  show: boolean;
  onHide: (save: boolean) => void;
  filterSearch: FilterSearch;
  selectedRolls: Roll[] | undefined;
  setSelectedRolls: (roll: Roll[] | undefined) => void;
  selectedRollset: Rollset | undefined;
  setSelectedRollset: (roll: Rollset | undefined) => void;
};

export const FilterRollModal = ({
  show,
  onHide,
  filterSearch,
  selectedRolls,
  setSelectedRolls,
  selectedRollset,
  setSelectedRollset,
}: Props) => {
  const classes = useGridStyles();

  const [filterValue, setFilterValue] = useState("");
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useState<FilterSearch>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchByRolls, setSearchByRolls] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");

  const { data: rolls } = getRolls({
    filterSearch: searchParams,
    onError: (error) => setError(error.message),
  });

  const { data: rollsets } = getRollsets({
    filterSearch: searchParams,
    onError: (error) => setError(error.message),
    onSuccess: (data: Rollset[]) => {
      if (data?.length < 1 && !searchByRolls && show) {
        setShowDialog(true);
      }
    },
  });

  const { control, reset, handleSubmit, setValue, watch } =
    useForm<FilterSearch>({
      mode: "onChange",
    });

  const values = watch();
  const isEmpty = Object.values(values).every((value) => !value);

  const onSubmit: SubmitHandler<FilterSearch> = async (data) => {
    onHide(true);
  };

  const handleOnSearch = () => {
    setSearchParams({
      SectionId: values.SectionId,
      StandId: values.StandId,
    });
  };

  const { columnDefs } = useFilterRollData();

  useEffect(() => {
    if (show) {
      reset();
      setSelectedRolls(undefined);
      setSelectedRollset(undefined);
      setValue("SectionId", filterSearch?.SectionId);
      setValue("StandId", filterSearch?.StandId);
    } else {
      setSearchParams(undefined);
    }
    setSearchByRolls(false);
  }, [show]);

  return (
    <>
      <MesfModal
        open={show}
        handleClose={() => onHide(false)}
        title="SEARCH ROLLS"
        maxWidth="sm"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <MesfModal.Content
            dividers
            style={{ padding: "15px 50px" }}
            enterKeyHint={"Enter"}
          >
            <Grid container justifyContent="center">
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={searchByRolls}
                        onChange={() => setSearchByRolls(!searchByRolls)}
                        name="search-by-rolls"
                        color="primary"
                      />
                    }
                    label="Search by Rolls"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="SectionId"
                  control={control}
                  render={({ field }) => (
                    <SectionSelector
                      label="Section"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="StandId"
                  control={control}
                  render={({ field }) => (
                    <LocationSelector
                      label="Stand Position"
                      value={field.value}
                      onChange={field.onChange}
                      onlyStands
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  value={filterValue}
                  onChange={(event) => {
                    setFilterValue(event?.target.value);
                    gridApi?.setQuickFilter(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" type="submit">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: 5 }}>
              <Grid item md={3} xs={12}>
                <Button
                  fullWidth
                  disabled={isEmpty ? true : false}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOnSearch()}
                >
                  <>
                    <SearchIcon fontSize="small" style={{ marginRight: 10 }} />
                    Search
                  </>
                </Button>
              </Grid>
              {searchByRolls && searchParams && (
                <Typography style={{ marginTop: 5, color: "grey" }}>
                  In search by rolls, you have to select two rolls (Top -
                  Bottom)
                </Typography>
              )}
            </Grid>
            {searchParams &&
              ((rollsets && rollsets?.length > 0) ||
                (searchByRolls && rolls)) && (
                <div className={classes.root}>
                  <Grid item md={12} xs={12}>
                    <div
                      style={{ height: "25vh", width: "100%" }}
                      className={`${classes.table} ag-theme-alpine`}
                    >
                      <AgGridReact
                        rowData={searchByRolls ? rolls : rollsets || []}
                        columnDefs={columnDefs}
                        rowHeight={27}
                        headerHeight={30}
                        animateRows={true}
                        rowSelection={searchByRolls ? "multiple" : "single"}
                        rowMultiSelectWithClick
                        suppressContextMenu
                        loadingOverlayComponent={CenteredLazyLoading}
                        onGridReady={(params) => setGridApi(params.api)}
                        getRowId={(params) =>
                          params.data.PartId
                            ? `${params.data.PartId} - ${params.data.StandId}`
                            : String(params.data.RollsetId)
                        }
                        onRowSelected={(params) => {
                          searchByRolls
                            ? setSelectedRolls(
                                selectedRolls
                                  ? [...selectedRolls, params.data]
                                  : [params.data]
                              )
                            : setSelectedRollset(params.data);
                        }}
                        overlayNoRowsTemplate="No rolls found with the specified parameters."
                      />
                    </div>
                  </Grid>
                </div>
              )}
          </MesfModal.Content>
          <MesfModal.Actions
            style={{ padding: "15px 30px" }}
            enterKeyHint={"Enter"}
          >
            <FooterModalButtons
              disabled={
                !selectedRollset &&
                (selectedRolls ? selectedRolls?.length < 2 : true)
              }
              onHide={() => onHide(false)}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <ErrorModal error={error} onHide={() => setError("")} />
      {/* Search by rolls confirm */}
      <ConfirmationDialogRaw
        open={showDialog}
        title="CONFIRM"
        onClose={(value?: string) => {
          setShowDialog(false);
          if (value === "OK") {
            setSearchByRolls(true);
            setSearchParams(undefined);
          }
        }}
        message="We don't have rollsets with those parameters, would you like to search by rolls?"
      />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
