import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { SpareCarrier } from "../models/SpareCarrier";

export const getReadyCarriers = async (
  campaignId: number,
  isNextCampaign?: boolean
) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "CampaignId", value: campaignId ?? 0 },
  ];

  if (isNextCampaign) {
    parameters.push({ name: "IsNextCampaign", value: isNextCampaign });
  }

  const resp = await apiService.callV2("[M2].[GetReadyCarriers]", parameters);

  if (resp.ok) {
    let rows = get(resp, "data.tables[0].rows", []);

    rows = rows.map((s: SpareCarrier) => {
      const sparesNames = s.Spares?.split(",");
      const sparesIds = s.SparesIds?.split(",");
      const sparesStatuses = s.SparesStatus?.split(",");

      const SparesFormatted = sparesNames
        ?.map((name, index) => ({
          name,
          status: sparesStatuses ? sparesStatuses[index] : "",
          id: sparesIds ? sparesIds[index] : null,
        }))
        .filter(
          (spare) => spare.name.trim() !== "" && spare.status.trim() !== ""
        );

      return {
        ...s,
        SparesFormatted,
      };
    });

    return rows as SpareCarrier[];
  } else {
    throw new Error(resp.message || "Error fetching carriers");
  }
};

export const getCurrentCampaign = async () => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  const resp = await apiService.callV2("[M2].[GetCurrentCampaign]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as [];
  } else {
    throw new Error(resp.message || "Error fetching current campaign");
  }
};

export const upsertScheduleCampaignStands = async ({
  campaignId,
  standId,
  comments,
}: {
  campaignId: number;
  standId: number;
  comments: string;
}) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "CampaignId", value: campaignId },
    { name: "StandId", value: standId },
    { name: "Comments", value: comments },
  ];

  const resp = await apiService.callV2(
    "[M2].[UpsertScheduleCampaignStands]",
    parameters
  );

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as [];
  } else {
    throw new Error(resp.message || "Error upserting comments");
  }
};
