import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Roll } from "../models/Roll";
import { Rollset } from "../models/Rolllset";

export interface FilterSearch {
  SectionId: number | null;
  StandId: number | null;
}

export const getReadyRollsets = async (filter?: FilterSearch | undefined) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (filter?.SectionId)
    parameters.push({ name: "SectionId", value: filter?.SectionId });
  if (filter?.StandId)
    parameters.push({ name: "StandId", value: filter?.StandId });

  const resp = await apiService.callV2("[M2].[GetReadyRollsets]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Rollset[];
  } else {
    throw new Error(resp.message || "Error fetching rollsets");
  }
};

export const getReadyRolls = async (filter?: FilterSearch | undefined) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (filter?.SectionId)
    parameters.push({ name: "SectionId", value: filter?.SectionId });
  if (filter?.StandId)
    parameters.push({ name: "StandId", value: filter?.StandId });

  const resp = await apiService.callV2("[M2].[GetReadyRolls]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Roll[];
  } else {
    throw new Error(resp.message || "Error fetching rolls");
  }
};
