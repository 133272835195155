import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@material-ui/core";

type LinearProgressWithLabelProps = LinearProgressProps & {
  value: number;
  withLabel?: boolean;
};

export const LinearProgressWithLabel = ({
  value,
  withLabel = false,
  ...props
}: LinearProgressWithLabelProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} {...props} />
      </Box>
      <Box minWidth={withLabel ? 90 : 40} marginLeft={withLabel ? 1 : 0}>
        <Typography variant="body2" color="textSecondary">{`${
          withLabel ? "Progress " : ""
        }${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
