import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal, getMomentTz, TimeService } from "@dexteel/mesf-core";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Moment } from "moment";
import { FooterDetailsButtons } from "../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { CommentsField } from "../../../../shared/components/CommentsField";
import { SectionSelector } from "../../../../shared/selectors";
import { Campaign, CAMPAIGN_INITIAL_VALUES } from "../../models/Campaign";
import { upsertCampaign } from "../../repositories/CampaignRepository";

const moment = getMomentTz();

type Props = {
  campaign: Campaign | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const CampaignDetails = ({ campaign, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The campaign was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { dirtyFields },
  } = useForm<Campaign>({
    mode: "onChange",
  });

  const isFormDirty =
    !(Object.keys(dirtyFields).length === 0) || modal === "create";

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertCampaign, {
    onSuccess: async (id: number) => {
      setNotificationMessage(succesfullyMessage);
      await queryClient.invalidateQueries(["campaigns"]);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Campaign> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      if (campaign?.IsDuplicate) {
        upsertMutation.mutate({ ...data, CampaignId: null });
      } else {
        upsertMutation.mutate(data);
      }
    }
  };

  const validateCampaignDuration = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (!startDate || !endDate) {
      return "Start date and end date are required.";
    }

    const differenceInDays = endDate.diff(startDate, "days");

    if (differenceInDays > 30) {
      return "The duration of the campaign cannot exceed 30 days.";
    }

    return true;
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(campaign || CAMPAIGN_INITIAL_VALUES);

      const startDate = campaign?.Start
        ? new Date(campaign?.Start)
        : new Date();
      setValue("Start", startDate);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 2);
      setValue(
        "End",
        campaign?.End === null || campaign?.End === undefined
          ? endDate
          : new Date(campaign?.End)
      );
      setIsSubmitLoading(false);
    }
  }, [modal, campaign]);

  return (
    <>
      {campaign === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a campaign</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 5 }}
            >
              <Grid item xs={12} md={6}>
                <Controller
                  name="CampaignNumber"
                  rules={{ required: "The campaign number can't be null" }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Campaign Number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      value={value ?? ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Controller
                  name="SectionId"
                  rules={{ required: "The section can't be null" }}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <SectionSelector
                      label="Section"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={modal === "delete"}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="Start"
                  control={control}
                  shouldUnregister
                  rules={{
                    deps: ["End"],
                    required: "Start date can't be null",
                    validate: {
                      validateDuration: (value) => {
                        const endDate = watch("End");
                        return validateCampaignDuration(
                          moment(value),
                          moment(endDate)
                        );
                      },
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <DateTimePicker
                        disabled={modal === "delete"}
                        label="Start"
                        format="MM/DD/YYYY HH:mm:ss"
                        views={["year", "month", "day", "hours", "minutes"]}
                        ampm={false}
                        timezone={TimeService.getInstance().getServerTimeZone()}
                        value={field.value ? moment(field.value) : null}
                        onChange={field.onChange}
                        slotProps={{
                          textField: {
                            size: "small",
                            error: !!error,
                          },
                        }}
                      />
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="End"
                  control={control}
                  shouldUnregister
                  rules={{
                    deps: ["Start"],
                    validate: {
                      notBeforeStart: (value) => {
                        const startDate = watch("Start");
                        if (value && startDate && value < startDate) {
                          return "End date cannot be before Start date.";
                        }
                        return true;
                      },
                      notSameAsStart: (value) => {
                        const startDate = watch("Start");
                        if (
                          value &&
                          startDate &&
                          moment(value).isSame(moment(startDate))
                        ) {
                          return "End date cannot be the same as Start date.";
                        }
                        return true;
                      },
                      validateDuration: (value) => {
                        const startDate = watch("Start");
                        return validateCampaignDuration(
                          moment(startDate),
                          moment(value)
                        );
                      },
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <DateTimePicker
                        disabled={modal === "delete"}
                        label="End"
                        format="MM/DD/YYYY HH:mm:ss"
                        views={["year", "month", "day", "hours", "minutes"]}
                        ampm={false}
                        timezone={TimeService.getInstance().getServerTimeZone()}
                        value={field.value ? moment(field.value) : null}
                        onChange={field.onChange}
                        slotProps={{
                          textField: {
                            size: "small",
                            error: !!error,
                          },
                        }}
                      />
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="ScheduledBillets"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Scheduled Billets"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      value={value ?? ""}
                      onChange={(event) =>
                        Number(event.target.value) < 0
                          ? onChange(null)
                          : onChange(Number(event.target.value))
                      }
                      disabled={modal === "delete"}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="ActualBillets"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Actual Billets"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      value={value ?? ""}
                      onChange={onChange}
                      disabled
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="ScheduledTons"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Scheduled Tons"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      value={value ?? ""}
                      onChange={(event) =>
                        Number(event.target.value) < 0
                          ? onChange(null)
                          : onChange(Number(event.target.value))
                      }
                      inputProps={{ min: 0 }}
                      disabled={modal === "delete"}
                      error={!!error}
                      helperText={error?.message}
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="ActualTons"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Actual Tons"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      value={value ?? ""}
                      onChange={onChange}
                      disabled
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <Controller
                  name="Comments"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CommentsField
                      value={field.value}
                      onChange={field.onChange}
                      fieldError={error}
                      getValues={getValues}
                      disabled={modal === "delete"}
                      rows={{ minRows: 6, maxRows: 6 }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <FooterDetailsButtons
                  isSubmitLoading={isSubmitLoading}
                  disabled={!isFormDirty}
                  onHide={() => onHide({ shouldUpdate: false, close: true })}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
