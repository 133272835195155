import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Campaign } from "../models/Campaign";

const moment = getMomentTz();

export const getCampaigns = async (campaignId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (campaignId) parameters.push({ name: "CampaignId", value: campaignId });

  const resp = await apiService.callV2("[M2].[GetCampaigns]", parameters);

  if (resp.ok) {
    let rows = get(resp, "data.tables[0].rows", []);

    rows = rows.map((campaign: Campaign) => ({
      ...campaign,
      Start: campaign.Start ? moment.utc(campaign["Start"]).toDate() : null,
      End: campaign.End ? moment.utc(campaign["End"]).toDate() : null,
    }));

    return rows as Campaign[];
  } else {
    throw new Error(resp.message || "Error fetching campaign");
  }
};
