import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Rollset, RollsetRolls } from "../models/Rollset";

export const getRollsets = async (rollsetId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (rollsetId) parameters.push({ name: "RollsetId", value: rollsetId });

  const resp = await apiService.callV2("[M2].[GetRollsets]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Rollset[];
  } else {
    throw new Error(resp.message || "Error fetching rollsets");
  }
};

export const upsertRollset = async ({
  rollsetId,
  field,
  value,
}: {
  rollsetId: number | null | undefined;
  field: string;
  value: boolean | string | number | Date | null;
}) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "RollsetId", value: rollsetId ?? null },
    { name: field, value: value },
  ];

  const resp = await apiService.callV2("[M2].[UpsertRollset]", parameters);

  if (resp.ok) {
    return get(resp, "data.returnValue", null) as number;
  } else {
    throw new Error(resp.message || "Error upserting rollset");
  }
};

export const deleteRollset = async (rollsetId: number) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [{ name: "RollsetId", value: rollsetId }];

  const resp = await apiService.callV2("[M2].[DeleteRollset]", parameters);

  if (!resp.ok) {
    throw new Error(resp.message || "Error deleting rollset");
  }
};

export const getRollsetRolls = async (rollsetId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "RollsetId", value: rollsetId ?? 0 },
  ];

  const resp = await apiService.callV2("[M2].[GetRollsetRolls]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as RollsetRolls[];
  } else {
    throw new Error(resp.message || "Error fetching rollset rolls");
  }
};
