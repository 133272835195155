import { Suspense } from "react";
import { Route } from "react-router-dom";
import Campaign from "src/pages/production/campaigns";
import { LazyLoading } from "../../controls/LazyLoading";

export const CampaignsRoutes = [
  <Route
    path="/campaigns"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <Campaign />
      </Suspense>
    }
  />,
  <Route
    path="/campaigns/:campaignId"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <Campaign />
      </Suspense>
    }
  />,
];
