import { Grid, TextField, Typography } from "@material-ui/core";
import { Part } from "../models/Part";
import { LogsModal } from "./log-modal/LogsModal";
import {
  SpecificArborData,
  SpecificCarrierData,
  SpecificCassetteData,
  SpecificChockData,
  SpecificDonutData,
  SpecificEdgerRollData,
  SpecificGuideData,
  SpecificUniversalRollData,
} from "./parts-panels/specifics.datas";

type Props = {
  part: Part | null;
  showDetails: keyof typeof components;
};

const components = {
  A: SpecificArborData,
  H: SpecificCarrierData,
  T: SpecificCassetteData,
  C: SpecificChockData,
  D: SpecificDonutData,
  E: SpecificEdgerRollData,
  G: SpecificGuideData,
  U: SpecificUniversalRollData,
  K: null,
  "": null,
};

export const PartDetails = ({ part, showDetails }: Props) => {
  const hideTag = part?.PartTypeCode === "D";

  const SpecificComponent = components[showDetails];

  return (
    <>
      {part === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a part</Typography>
        </div>
      ) : (
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item md={6} sm={12} xs={12}>
            <TextField
              value={part?.PartName || ""}
              label="Name"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          {!hideTag && (
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={part?.RFIDTag || ""}
                label="Tag"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          )}
          <Grid item md={!hideTag ? 12 : 6} sm={12} xs={12}>
            <TextField
              value={part?.LocationName || ""}
              label="Location"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            {SpecificComponent && <SpecificComponent part={part} />}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <LogsModal partId={part?.PartId!} partName={part?.PartName} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
