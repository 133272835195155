import { Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { CommentsField } from "../../../../../../shared/components/CommentsField";
import { Stand } from "../../../../models/Stand";
import { Arbor, ARBOR_INITIAL_VALUES } from "../../../../models/parts/Arbor";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { upsertArbor } from "../../../../repositories/parts/ArborRepository";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  arbor: Arbor | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const ArborDetails = ({ arbor, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The arbor was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useForm<Arbor>({
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: arbor?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });
  const standsJson = JSON.stringify(
    standsSelected.map((stand: Stand) => ({
      ...stand,
      PartId: arbor?.PartId,
      PartName: arbor?.PartName,
    }))
  );
  const oldStandsJson = JSON.stringify(standsOfPart);

  const isFormDirty =
    !(Object.keys(dirtyFields).length === 0) ||
    standsJson !== oldStandsJson ||
    modal == "create";

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertArbor, {
    onSuccess: async (id: number) => {
      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }

      setNotificationMessage(succesfullyMessage);
      await queryClient.invalidateQueries(["arbors"]);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Arbor> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      if (arbor?.IsDuplicate) {
        upsertMutation.mutate({ ...data, PartId: null });
      } else {
        upsertMutation.mutate(data);
      }
    }
  };

  useEffect(() => {
    if (modal !== "") {
      reset(arbor || ARBOR_INITIAL_VALUES);
      setIsSubmitLoading(false);
      if (!arbor?.IsDuplicate) setStandsSelected([]);
    }
  }, [modal, arbor]);

  return (
    <>
      {arbor === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select an arbor or create a new one</Typography>
        </div>
      ) : (
        <form id="upsert-arbor-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Arbor>
              control={control}
              disabled={modal === "delete"}
            />
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid item md={12} sm={12} style={{ marginBottom: 10 }}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CommentsField
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    getValues={getValues}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: "0px 15px" }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
