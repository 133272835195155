import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Carrier } from "../models/Carrier";

export const getCarriers = async (partId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (partId) parameters.push({ name: "PartId", value: partId });

  const resp = await apiService.callV2("[M2].[GetCarriers]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Carrier[];
  } else {
    throw new Error(resp.message || "Error fetching carriers");
  }
};

type assembleCarrierProps = {
  partId: number;
  field: string;
  value: boolean | string | number | Date | null;
  force: boolean;
};

export const assemblyCarrier = async ({
  partId,
  field,
  value,
  force,
}: assembleCarrierProps) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [
    { name: "PartId", value: partId },
    { name: field, value: value || null },
    { name: "Force", value: force ? 1 : 0 },
  ];

  const resp = await apiService.callV2("[M2].[AssembleCarrier]", parameters);

  if (resp.ok) {
    return get(resp, "data.returnValue", null) as number;
  } else {
    throw new Error(resp.message || "Error upserting carriers");
  }
};
