import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { SpareCarrier } from "../models/SpareCarrier";
import { getReadyCarriers } from "../repositories/SparesRepository";
import { TableSpare } from "./components/TableSpare";

type useReadyCarriersProps = {
  onError: (error: Error) => void;
  campaignId: number;
  isNextCampaign?: boolean;
  onSuccess?: (data: any[]) => void;
};

export const useReadyCarriers = ({
  campaignId,
  isNextCampaign,
  onError,
}: useReadyCarriersProps) => {
  return useQuery<SpareCarrier[], Error>({
    queryKey: ["readyCarriers", campaignId, isNextCampaign],
    queryFn: () => getReadyCarriers(campaignId, isNextCampaign),
    onError: onError,
    refetchInterval: 60000, // miliseconds
  });
};

export const SparePage = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1}>
            <TableSpare />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
