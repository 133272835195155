import { ErrorModal } from "@dexteel/mesf-core";
import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Rollset } from "../models/Rolllset";
import { FilterSearch } from "../repositories/FilterRollsRepository";
import { getRollsets } from "../repositories/RollsetRepository";

type useRollsetsProps = {
  onError: (error: Error) => void;
  onSuccess?: (data: Rollset[]) => void;
  filterSearch?: FilterSearch | undefined;
};

export const useRollsets = ({
  filterSearch,
  onError,
  onSuccess,
}: useRollsetsProps) => {
  return useQuery<Rollset[], Error>({
    queryKey: ["rollsets", filterSearch],
    queryFn: () => getRollsets(filterSearch),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  filterSearch?: FilterSearch | undefined;
  setShowFilterModal?: (show: boolean) => void;
  disabled?: boolean;
};
export const RollsetSelector = ({
  filterSearch,
  setShowFilterModal = () => {},
  disabled = false,
}: Props) => {
  const [error, setError] = useState("");

  const { data: rollsets, isLoading } = useRollsets({
    filterSearch: filterSearch,
    onError: (error) => setError(error.message),
  });

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setShowFilterModal(true)}
        size="small"
        disabled={disabled || isLoading}
        fullWidth
      >
        Advanced Search
      </Button>
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading rollsets"
      />
    </>
  );
};
