export interface Campaign {
  CampaignId: number | null;
  CampaignNumber: string | null;
  SectionId: number | null;
  SectionName: string | null;
  Start: Date;
  End: Date;
  Duration: number | null;
  ScheduledBillets: number | null;
  ActualBillets: number | null;
  ScheduledTons: number | null;
  ActualTons: number | null;
  Comments: string;
  ProgressBar: number | null;
  IsDuplicate: boolean | null;
  IsActive: boolean | null;
}

export const CAMPAIGN_INITIAL_VALUES: Campaign = {
  CampaignId: null,
  CampaignNumber: null,
  SectionId: null,
  SectionName: "",
  Start: new Date(),
  End: new Date(),
  Duration: null,
  ScheduledBillets: null,
  ActualBillets: null,
  ScheduledTons: null,
  ActualTons: null,
  Comments: "",
  ProgressBar: 0,
  IsDuplicate: false,
  IsActive: true,
};
