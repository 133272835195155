import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, IconButton, Popover, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { upsertScheduleCampaignStands } from "../../..//repositories/SparesRepository";
import { SpareCarrier } from "../../../models/SpareCarrier";

const useStyles = makeStyles((theme) => ({
  buttons: {
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    ".ag-row-hover &": {
      opacity: 1,
    },
    ".ag-row-focus &": {
      opacity: 1,
    },
    ".ag-row-selected &": {
      opacity: 1,
    },
  },
}));

export const CommentsColumn = ({
  carrier,
  setNotificationMessage,
}: {
  carrier: SpareCarrier;
  setNotificationMessage: (message: string) => void;
}) => {
  const classes = useStyles();
  const color = carrier.Comments ? "green" : "";

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [comment, setComment] = useState<string>(carrier.Comments);
  const [error, setError] = useState<string>("");

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertScheduleCampaignStands, {
    onSuccess: () => {
      queryClient.invalidateQueries(["readyCarriers"]);
      setNotificationMessage("Comment saved successfully");
    },
    onError: (error: Error) => {
      setError(error.message);
    },
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (comment !== carrier.Comments) {
      upsertMutation.mutate({
        campaignId: carrier.CampaignId,
        standId: carrier.StandId,
        comments: comment,
      });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        className={classes.buttons}
        style={{ alignItems: "center" }}
      >
        <Grid item md={12} xs={12}>
          <IconButton
            onClick={handleClick}
            style={{
              textDecoration: "none",
              fontWeight: 500,
              fontSize: "12px",
              borderRadius: "3px",
              marginRight: 8,
              color,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TextField
          multiline
          minRows={6}
          variant="outlined"
          fullWidth
          placeholder="Enter the comments here"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          style={{ width: "500px" }}
        />
      </Popover>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
