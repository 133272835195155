import { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const CustomNavbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setDropdownOpen(false);
  }, [location]);

  return (
    <>
      <NavDropdown title="Delays" id="nav-dropdown">
        <NavDropdown.Item as={Link} to="/delays-manager">
          Manager
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/delays-report">
          Report
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/delays-pareto">
          Pareto
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Assembly" id="nav-dropdown">
        <NavDropdown.Item as={Link} to={"/carriers-assembly"}>
          Carriers
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={"/rollsets"}>
          Rollsets
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={"/spare"}>
          Spare
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Report" id="nav-dropdown">
        <NavDropdown.Item as={Link} to={"/report-parts"}>
          Parts
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Campaigns" id="nav-dropdown">
        <NavDropdown.Item as={Link} to={"/campaigns"}>
          Data Entry
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export { CustomNavbar };
