import { ColDef } from "ag-grid-enterprise";
import { Roll } from "../../../../shared/models/Roll";
import { rollPositionCodes } from "../../../../shared/selectors/RollPositionSelector";
import { optionsTypeCode } from "../../../../shared/selectors/RollTypeSelector";

const cellStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const useFilterRollData = () => {
  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      valueGetter: (params: any) =>
        params.data.PartId ? params.data.PartName : params.data.RollsetName,
      flex: 2,
      cellStyle: cellStyle,
    },
    {
      field: "RollTypeCode",
      headerName: "Type",
      valueGetter: (params: any) =>
        optionsTypeCode.find((c) => c.Code === params.data.RollTypeCode)?.Name,
      flex: 2,
      cellStyle: cellStyle,
    },
    {
      field: "RollPosition",
      headerName: "Position",
      valueGetter: (params) =>
        rollPositionCodes.find(
          (position) => position.Code === (params.data as Roll).RollPosition
        )?.Name ?? "-",
      flex: 1,
      cellStyle: cellStyle,
    },
  ];
  return { columnDefs };
};
