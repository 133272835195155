import { dxtToLocalServerTime, ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField, Typography } from "@material-ui/core";
import { GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";

import { CenteredLazyLoading } from "../../../../controls/LazyLoading";

import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import {
  CampaignSelector,
  SectionSelector,
} from "../../../../shared/selectors";

import { useCampaigns } from "../../../../pages/production/campaigns/CampaignPage";
import { LinearProgressWithLabel } from "../../../../shared/components/LinearProgressWithLabel";
import { useGridStyles } from "../../../configuration/styles/gridStyles";
import { useTableDataLeft, useTableDataRight } from "../hooks/useTableData";
import { useReadyCarriers } from "../SparePage";

const formatSecondsToTime = (
  totalSeconds: number | null | undefined
): string => {
  if (!totalSeconds) {
    return "00:00:00 hs";
  }

  const isNegative = totalSeconds < 0;
  const absSeconds = Math.abs(totalSeconds);

  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.floor((absSeconds % 3600) / 60);
  const seconds = absSeconds % 60;

  const pad = (value: number) => String(value).padStart(2, "0");

  const formattedTime = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;

  return `${isNegative ? "- " : ""}${formattedTime} hs`;
};

export const TableSpare = () => {
  const classes = useGridStyles();

  const [currentGridApi, setCurrentGridApi] = useState<GridApi | null>(null);
  const [nextGridApi, setNextGridApi] = useState<GridApi | null>(null);
  const [currentCampaignId, setCurrentCampaignId] = useState<number>(1);
  const [error, setError] = useState("");
  const [message, setNotificationMessage] = useState("");

  const { columnDefsLeft } = useTableDataLeft({ setNotificationMessage });
  const { columnDefsRight } = useTableDataRight({ setNotificationMessage });

  const { data: campaigns } = useCampaigns({
    campaignId: currentCampaignId,
    onError: (error: Error) => setError(error.message),
  });
  const currentCampaign = campaigns ? campaigns[0] : null;

  const { data: currentCarriers, isLoading: currentLoading } = useReadyCarriers(
    {
      campaignId: currentCampaignId,
      onError: (error: Error) => setError(error.message),
    }
  );
  const { data: nextCarriers, isLoading: nextLoading } = useReadyCarriers({
    campaignId: currentCampaignId,
    isNextCampaign: true,
    onError: (error: Error) => setError(error.message),
  });

  const { data: campaignss } = useCampaigns({
    campaignId: nextCarriers ? nextCarriers[0].CampaignId : currentCampaignId,
    onError: (error: Error) => setError(error.message),
  });
  const nextCampaign = campaignss ? campaignss[0] : null;

  const remainingTimeToStart = Math.floor(
    (new Date(nextCampaign?.Start!).getTime() - Date.now()) / 1000
  );

  useEffect(() => {
    if (currentLoading) {
      currentGridApi?.showLoadingOverlay();
    } else {
      currentGridApi?.hideOverlay();
    }
    if (nextLoading) {
      nextGridApi?.showLoadingOverlay();
    } else {
      nextGridApi?.hideOverlay();
    }
  }, [currentLoading, nextLoading, currentGridApi, nextGridApi]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography
            variant="h5"
            style={{ margin: "15px 0 15px", fontWeight: 600 }}
          >
            Spares
          </Typography>
        </Grid>
        <Grid container>
          <Grid container md={6} direction="row">
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <CampaignSelector
                label="Current Campaign"
                value={currentCampaignId}
                onChange={(val: number) => setCurrentCampaignId(val)}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <SectionSelector
                label="Section"
                value={currentCampaign?.SectionId!}
                disabled
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <TextField
                label="Start"
                variant="outlined"
                value={
                  dxtToLocalServerTime(
                    currentCampaign?.Start,
                    "MM/dd/yy HH:mm"
                  ) || ""
                }
                disabled
                size="small"
                fullWidth
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <TextField
                label="End"
                variant="outlined"
                value={
                  dxtToLocalServerTime(
                    currentCampaign?.End,
                    "MM/dd/yy HH:mm"
                  ) || ""
                }
                disabled
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Scheduled Tons"
                value={currentCampaign?.ScheduledTons ?? ""}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Actual Tons"
                value={currentCampaign?.ActualTons ?? ""}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Scheduled Billets"
                value={currentCampaign?.ScheduledBillets ?? ""}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Actual Billets"
                value={currentCampaign?.ActualBillets ?? ""}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
              <Grid item md={9} xs={12} style={{ padding: "0 15px" }}>
                <LinearProgressWithLabel
                  value={
                    ((currentCampaign?.ActualTons ?? 0) /
                      (currentCampaign?.ScheduledTons ?? 1)) *
                    100
                  }
                  withLabel
                />
              </Grid>
              <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
                <TextField
                  label="Remaining Time"
                  value={formatSecondsToTime(
                    Math.max(
                      Math.floor(
                        (new Date(nextCampaign?.End!).getTime() - Date.now()) /
                          1000
                      ),
                      0
                    )
                  )}
                  variant="outlined"
                  size="small"
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={6} direction="row">
            <Grid
              item
              md={8}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <CampaignSelector
                label="Current Campaign"
                value={nextCampaign?.CampaignId!}
                onChange={(val: number) => setCurrentCampaignId(val)}
                disabled
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <SectionSelector
                label="Section"
                value={nextCampaign?.SectionId!}
                disabled
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <TextField
                label="Scheduled Start"
                variant="outlined"
                value={
                  dxtToLocalServerTime(nextCampaign?.Start, "MM/dd/yy HH:mm") ||
                  ""
                }
                disabled
                size="small"
                fullWidth
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ padding: "0 15px", marginBottom: 10 }}
            >
              <TextField
                label="Scheduled End"
                variant="outlined"
                value={
                  dxtToLocalServerTime(nextCampaign?.End, "MM/dd/yy HH:mm") ||
                  ""
                }
                disabled
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Scheduled Tons"
                value={nextCampaign?.ScheduledTons ?? ""}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={3} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Scheduled Billets"
                value={nextCampaign?.ScheduledBillets ?? ""}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
              <TextField
                label="Remaining Time to Start"
                value={formatSecondsToTime(remainingTimeToStart)}
                InputProps={{
                  style: {
                    color:
                      remainingTimeToStart < 0
                        ? "red"
                        : remainingTimeToStart < 3600
                        ? "orange"
                        : "inherit",
                  },
                }}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%", padding: "0 10px" }}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <div
              style={{ position: "relative", height: "73vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<any>
                rowData={currentCarriers || []}
                columnDefs={columnDefsLeft}
                rowHeight={40}
                headerHeight={34}
                animateRows
                suppressRowClickSelection
                onGridReady={(params) => setCurrentGridApi(params.api)}
                getRowId={(params) => String(params.data.StandName)}
                loadingOverlayComponent={CenteredLazyLoading}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div
              style={{ position: "relative", height: "73vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<any>
                rowData={nextCarriers || []}
                columnDefs={columnDefsRight}
                rowHeight={40}
                headerHeight={34}
                animateRows
                suppressRowClickSelection
                onGridReady={(params) => setNextGridApi(params.api)}
                getRowId={(params) => String(params.data.StandName)}
                loadingOverlayComponent={CenteredLazyLoading}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
