import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Campaign } from "../models/Campaign";
import { getCampaigns } from "../repositories/CampaignRepository";
import { TableCampaign } from "./components/TableCampaign";

type useCampaignsProps = {
  onError: (error: Error) => void;
  campaignId?: number;
  onSuccess?: (data: any[]) => void;
};

export const useCampaigns = ({ campaignId, onError }: useCampaignsProps) => {
  return useQuery<Campaign[], Error>({
    queryKey: ["campaigns", campaignId],
    queryFn: () => getCampaigns(campaignId),
    onError: onError,
    refetchInterval: 60000, // miliseconds
  });
};

export const CampaignPage = () => {
  const { campaignId } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1}>
            <TableCampaign campaignId={campaignId} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
