import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { PartLog } from "../../models/Part";
import { logTypeCodes, LogTypeTag } from "../log-modal/LogTypeTag";

export const useTableData = () => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "Timestamp",
      headerName: "Timestamp",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        dxtToLocalServerTime(params?.data.Timestamp, "MM/dd/yy HH:mm") || "",
    },
    {
      field: "PartLogTypeCode",
      headerName: "Type",
      minWidth: 20,
      maxWidth: 70,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        logTypeCodes.find(
          (code) => code.Code === (params.data as PartLog).PartLogTypeCode
        )?.Name,
      cellRenderer: LogTypeTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        type: logTypeCodes.find(
          (code) => code.Code === (params.data as PartLog).PartLogTypeCode
        ),
      }),
    },
    {
      field: "Description",
      headerName: "Description",
      minWidth: 300,
      flex: 2,
      cellStyle: cellStyle,
    },
  ];
  return { columnDefs };
};
