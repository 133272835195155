import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, IconButton, TextField } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FieldError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Campaign } from "../models/Campaign";
import { getCampaigns } from "../repositories/CampaignRepository";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  disabled?: boolean;
  fieldError?: FieldError;
};

const useCampaigns = (
  campaignId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Campaign[], Error>({
    queryKey: ["campaigns", campaignId],
    queryFn: () => getCampaigns(campaignId),
    onError: onError,
  });
};

export const CampaignSelector = ({
  label,
  value,
  onChange = () => {},
  disabled = false,
  fieldError,
}: Props) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { data: campaigns, isLoading } = useCampaigns(undefined, (error) =>
    setError(error.message)
  );

  const selectedValue =
    campaigns?.find((campaign) => campaign.CampaignId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={campaigns || []}
        getOptionLabel={(option) => `${option.CampaignNumber}`}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            error={!!fieldError}
            helperText={fieldError?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <>
                      {params.InputProps.endAdornment}
                      {value ? (
                        <IconButton
                          onClick={() => navigate(`/campaigns/${value}`)}
                          size="small"
                          title="See in campaigns"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      ) : null}
                    </>
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.CampaignId ?? null);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading campaigns"
      />
    </>
  );
};
