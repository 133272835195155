import { dxtToLocalServerTime, ErrorModal } from "@dexteel/mesf-core";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FieldError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialogRaw } from "../../controls/modals/ConfirmationDialogRaw";
import {
  autocompleteOptionColor,
  useAutocompleteOptionStyles,
} from "../../utils/theme";
import { Chock } from "../models/Chock";
import { getChocks } from "../repositories/ChockRepository";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  fieldError?: FieldError | undefined;
  disabled?: boolean;
  onForceAssembly?: (force: boolean) => void;
};

const useChocks = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Chock[], Error>({
    queryKey: ["chocks", partId],
    queryFn: () => getChocks(partId),
    onError: onError,
  });
};

export const ChockSelector = ({
  label,
  value,
  onChange = () => {},
  fieldError,
  disabled = false,
  onForceAssembly = () => {},
}: Props) => {
  const classes = useAutocompleteOptionStyles();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState<Chock | null>(null);

  const { data: chocks, isLoading } = useChocks(undefined, (error) =>
    setError(error.message)
  );

  const selectedChock = chocks?.find((chock) => chock.PartId === value) || null;

  const inUse = (props: Chock | null) => {
    if (props?.InUse && !props.IsUnident) return true;
    return false;
  };

  const handleConfirmationClose = (result?: string) => {
    setMessage("");
    if (result === "OK") {
      onForceAssembly(true);
      onChange(selectedValue?.PartId ?? null);
      setSelectedValue(null);
      return;
    }
  };

  const unidentId = chocks?.find((c) => c.IsUnident)?.PartId;
  const checkboxChecked = unidentId === value;

  return (
    <Box
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "15px 10px 5px",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box style={{ flex: 3 }}>
          <Autocomplete
            id="clear-on-escape"
            clearOnEscape
            options={
              chocks?.filter((c) => checkboxChecked || !c.IsUnident) || []
            }
            getOptionLabel={(option) => option.PartName}
            renderOption={(props, option) => {
              return (
                <Box
                  bgcolor={inUse(props) ? autocompleteOptionColor : ""}
                  component="li"
                  className={classes.optionBox}
                >
                  <span>
                    <Typography>
                      {props.PartName}
                      <i>
                        {inUse(props)
                          ? props?.PartId == value
                            ? " (Actual) "
                            : " (In Use)"
                          : ""}
                      </i>
                    </Typography>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "8px",
                      marginTop: 7,
                    }}
                  >
                    <Typography variant="body2">
                      Rebuild Date:{" "}
                      {dxtToLocalServerTime(
                        props?.RebuildTimestamp,
                        "MM/dd/yy"
                      )}
                    </Typography>
                    <Typography variant="body2" color="primary">
                      Bearing Change Date:{" "}
                      {dxtToLocalServerTime(
                        props?.BearingChangeTimestamp,
                        "MM/dd/yy"
                      )}
                    </Typography>
                  </div>
                </Box>
              );
            }}
            getOptionSelected={(option, value) => {
              if (value) {
                return option.PartId === value.PartId;
              } else {
                return false;
              }
            }}
            disabled={disabled || checkboxChecked}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                size="small"
                error={!!fieldError}
                helperText={fieldError?.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <>
                          {params.InputProps.endAdornment}
                          {value ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/configuration/chocks/${value}`)
                              }
                              size="small"
                              title="See in chocks"
                            >
                              <VisibilityIcon />
                            </IconButton>
                          ) : null}
                        </>
                      )}
                    </>
                  ),
                }}
              />
            )}
            value={selectedChock}
            onChange={(event, newValue) => {
              if (inUse(newValue)) {
                setMessage(
                  `The chock ${newValue?.PartName} is already in use by another carrier, do you want to disassemble it?`
                );
                setSelectedValue(newValue);
              } else onChange(newValue?.PartId ?? null);
            }}
          />
        </Box>
        <Box style={{ flex: 2, display: "flex", justifyContent: "flex-start" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={() => {
                  if (!checkboxChecked) {
                    onChange(unidentId);
                  } else {
                    onChange(null);
                  }
                }}
                name="Unidentified"
                color="primary"
              />
            }
            label="Unidentified"
          />
        </Box>
      </Box>
      <Collapse in={!!selectedChock} style={{ margin: 5 }}>
        <Typography variant="body2" color="textSecondary">
          {`Rebuild Date: ${dxtToLocalServerTime(
            selectedChock?.RebuildTimestamp,
            "MM/dd/yy"
          )}
          - Bearing Change Date: ${dxtToLocalServerTime(
            selectedChock?.BearingChangeTimestamp,
            "MM/dd/yy"
          )}`}
        </Typography>
      </Collapse>
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading chocks"
      />
      <ConfirmationDialogRaw
        title="CONFIRMATION"
        message={message}
        open={message !== ""}
        onClose={handleConfirmationClose}
      />
    </Box>
  );
};
