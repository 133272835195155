import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const logTypeCodes = [
  { Code: "C", Name: "Create", Color: "#009460" },
  { Code: "U", Name: "Update", Color: "#fad215" },
  { Code: "D", Name: "Delete", Color: "#ce2029" },
  { Code: "A", Name: "Assemble", Color: "#004999" },
  { Code: "O", Name: "Other", Color: "#c2c2c2" },
];

const useStyles = makeStyles({
  colorCircle: {
    width: "26px",
    height: "26px",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "bold",
  },
});
type TypeTagProps = {
  type: { Code: string; Name: string };
};
export const LogTypeTag = ({ type }: TypeTagProps) => {
  const classes = useStyles();
  if (!type || !type.Code || !type.Name) {
    return null;
  }
  let backgroundColor;
  backgroundColor = logTypeCodes.find((lt) => lt.Code === type.Code)?.Color;

  return (
    <Tooltip title={type.Name} arrow>
      <div
        className={classes.colorCircle}
        style={{
          backgroundColor,
        }}
      >
        {type.Code.charAt(0).toUpperCase()}
      </div>
    </Tooltip>
  );
};
