import { makeStyles, TextField } from "@material-ui/core";
import { FieldError } from "react-hook-form";

const useCommentStyles = makeStyles(() => ({
  errorLabel: {
    fontSize: 12,
    color: "#F44336",
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
}));

type Props = {
  value: string;
  onChange: Function;
  fieldError?: FieldError | undefined;
  getValues?: Function;
  disabled?: boolean;
  rows?: { minRows: number; maxRows: number };
};

export const CommentsField = ({
  value,
  onChange,
  fieldError,
  getValues = () => {},
  disabled = false,
  rows = { minRows: 3, maxRows: 3 },
}: Props) => {
  const classes = useCommentStyles();

  return (
    <>
      <TextField
        label="Comments"
        variant="outlined"
        size="small"
        fullWidth
        value={value ?? ""}
        maxRows={rows.maxRows}
        minRows={rows.minRows}
        multiline
        autoComplete="off"
        disabled={disabled}
        onChange={(event) => {
          if (event.target.value.length <= 200) {
            onChange(event.target.value);
          }
        }}
        error={!!fieldError}
        helperText={fieldError?.message}
      />
      {(getValues("Comments")?.length as number) !== 200 && (
        <span className={classes.infoLabel} style={{ marginLeft: 5 }}>
          {`${getValues("Comments")?.length ?? 0}/200 max.`}
        </span>
      )}
      {(getValues("Comments")?.length as number) == 200 && (
        <span className={classes.infoLabel} style={{ marginLeft: 5 }}>
          Max. 200
        </span>
      )}
    </>
  );
};
