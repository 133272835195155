import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Button, Grid, Paper } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { TextFieldSearchFilter } from "../../../../../shared/components/TextFieldSearchFilter";
import { PartLog } from "../../models/Part";
import { getPartLogs } from "../../repositories/PartsReportRepository";
import { useGridStyles } from "../../styles/gridStyles";
import { useTableData } from "./useTableData";

const usePartLogs = (partId: number, onError?: (error: Error) => void) => {
  return useQuery<PartLog[], Error>({
    queryKey: ["partLogs", partId],
    queryFn: () => getPartLogs(partId),
    onError: onError,
  });
};

export const LogsModal = ({
  partId,
  partName,
}: {
  partId: number;
  partName: String;
}) => {
  const classes = useGridStyles();

  const [filterValue, setFilterValue] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState("");

  const { data: partLogs, isLoading } = usePartLogs(partId, (error) =>
    setError(error.message)
  );

  const { columnDefs } = useTableData();

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Show logs
      </Button>
      <MesfModal
        open={isOpen}
        handleClose={() => setOpen(false)}
        maxWidth="md"
        title={`Logs History of: ${partName}`}
      >
        <MesfModal.Content
          dividers
          style={{ padding: "15px" }}
          enterKeyHint={"Enter"}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: "0 15px" }}
            className={classes.root}
          >
            <Grid item md={12} xs={12}>
              <TextFieldSearchFilter
                value={filterValue}
                onChange={(val) => {
                  setFilterValue(val);
                  gridApi?.setQuickFilter(val);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={4} style={{ padding: 6 }}>
                <div
                  style={{ height: "39vh", padding: 5 }}
                  className="ag-theme-alpine"
                >
                  <AgGridReact
                    rowData={partLogs}
                    columnDefs={columnDefs}
                    rowHeight={40}
                    headerHeight={34}
                    animateRows
                    rowSelection="single"
                    suppressContextMenu
                    onGridReady={(params) => setGridApi(params.api)}
                    loadingOverlayComponent={CenteredLazyLoading}
                    getRowId={(params) =>
                      `${(params.data as PartLog).PartId}-${
                        (params.data as PartLog).Timestamp
                      }`
                    }
                    gridOptions={{
                      localeText: {
                        noRowsToShow: "No logs to show",
                      },
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </MesfModal.Content>
        <MesfModal.Actions
          style={{ padding: "15px 30px" }}
          enterKeyHint={"Enter"}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            CLOSE
          </Button>
        </MesfModal.Actions>
      </MesfModal>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
