import { TimeService } from "@dexteel/mesf-core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import { Routes } from "react-router-dom";
import { AssemblyRoutes } from "./routes/assembly.routes";
import { DelayRoutes } from "./routes/delay.routes";
import { CampaignsRoutes } from "./routes/production.routes";
import { ReportRoutes } from "./routes/report.routes";

const timezone = TimeService.getInstance().getServerTimeZone();
moment.tz.setDefault(timezone);

const CustomRoutes = () => (
  <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
    <Routes>
      {DelayRoutes}, {AssemblyRoutes}, {ReportRoutes}, {CampaignsRoutes}
    </Routes>
  </LocalizationProvider>
);

export { CustomRoutes };
